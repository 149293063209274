import React, { useRef, memo } from "react";
import {
    TextField,
    ListItem,
    ListItemText,
    Select,
    Typography
} from "@material-ui/core";
import styled from "styled-components";

import getObjectHash from "common/utils/getObjectHash";
import { emailRegex } from "js/core/utilities/regex";
import { TEAM_USER_ROLES, TEAM_USER_LICENSE_STATUS } from "common/constants";

import Icon from "js/react/components/Icon";

const Container = styled.div`
    max-height: 490px;
    overflow-y: auto;
    overflow-x: hidden;

    ::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 18px;
        height: 8px;
    }

    ::-webkit-scrollbar-thumb:vertical {
        border-radius: 20px;
        background-color: rgba(0, 0, 0, 0.3);
        border: 5px solid transparent;
        background-clip: content-box;
    }
`;

const MemberContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    ${({ isSignUpB }) => !isSignUpB && "align-items: center;"}
    min-width: 300px;
    margin-top: 5px;

    >i.micon {
        font-size: 20px;
        color: #bbb;
        cursor: pointer;
    }
`;

const EmailInput = styled(TextField)`
    &&& {
        input {
            font-size: ${({ isSignUpB }) => isSignUpB ? "16px" : "14px"};
            ::placeholder {
                font-weight: 900;
            }
        }
        margin: 0 5px 5px 0;
        min-width: 270px;
    }
`;

const AddMemberSpan = styled.span`
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.26);
    font-weight: bolder;
    font-size: 12px;
`;

const AddMembersControlsContainer = styled.div`
    margin-top: 5px;
    user-select: none;
    display: flex;
    align-items: center;
`;

const AddMemberControlContainer = styled.div`
    display: flex;
    align-items: center;
    cursor: ${({ disabled }) => disabled ? "not-allowed" : "pointer"};
    position: relative;

    >i.micon {
        font-size: 30px;
        color: ${({ disabled }) => disabled ? "#e0e0e0" : "#11a9e2"};
        padding-right: 5px;        
    }

    >span {
        margin-right: 30px;
    }
`;

const UploadFileInput = styled.input`
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: none;
`;

const StyledRoleSelect = styled(Select)`
    ${({ isSignUpB }) => isSignUpB && `
        .MuiSelect-select {
            padding-left: 0px;
            color: rgb(48, 49, 61);
        }
    `}

    &&& {
        width: 115px;
        margin: ${({ isSignUpB }) => (isSignUpB ? "8px 5px 0px 5px" : "0 5px 5px")};
    }
`;

const BlockTypography = styled(Typography)`
    &&& {
        display: block;
        font-weight: ${({ bold }) => bold ? "900" : "unset"};
    }
`;

const SelectedRoleTypography = styled(Typography)`
    &&& {
        text-transform: capitalize;
        font-size: ${({ isSignUpB }) => isSignUpB ? "16px" : "14px"};
        font-weight: ${({ isSignUpB }) => isSignUpB ? "400" : "900"};
        ${({ isSignUpB }) => isSignUpB && `
            margin-left: 0;
        `}
    }
`;

const RoleLabel = styled.div`
    font-size: ${({ isSignUpB }) => isSignUpB ? "20px" : "15px"};
    font-weight: 600;
    color: ${({ isSignUpB }) => isSignUpB ? "#222" : "#333"};
    margin-bottom: 10px;
    ${({ isSignUpB }) => isSignUpB && `
        font-family: "Source Sans Pro";
        font-style: normal;
        line-height: 120%;
        margin-bottom: 18px;
    `}
`;

const roleOptions = [
    { label: "Owner", value: TEAM_USER_ROLES.OWNER, license: TEAM_USER_LICENSE_STATUS.TEAM_PRO, description: "Manages members, team slides and theme" },
    { label: "Librarian", value: TEAM_USER_ROLES.LIBRARIAN, license: TEAM_USER_LICENSE_STATUS.TEAM_PRO, description: "Manages team slides and theme" },
    { label: "Pro Member", value: TEAM_USER_ROLES.MEMBER_LICENSED, license: TEAM_USER_LICENSE_STATUS.TEAM_PRO, description: "Has access to Beautiful.ai Pro features" },
    { label: "Free Member", value: TEAM_USER_ROLES.MEMBER, license: TEAM_USER_LICENSE_STATUS.TEAM_PRO, description: "Limited to basic features" }
];

function _RoleSelect({ member, onUpdateMember, isSignUpB = false, isFirst = false }) {
    const memberRole = roleOptions.find(({ value }) => member.role === value);

    return (
        <StyledRoleSelect
            disabled={member.isDisabled}
            value={member.role}
            {...(!isSignUpB && { variant: "outlined" })}
            isSignUpB={isSignUpB}
            isFirst={isFirst}
            renderValue={() => (
                <SelectedRoleTypography component="span" color="textPrimary" isSignUpB={isSignUpB}>
                    {memberRole.label}
                </SelectedRoleTypography>
            )}
            onChange={event => onUpdateMember(member.id, "role", event.target.value)}
        >
            {roleOptions.map((role, index) => (
                <ListItem
                    value={role.value}
                    key={index}
                    button
                    className="override-selected"
                >
                    <ListItemText
                        primary={
                            <BlockTypography component="span" color={role.value === memberRole.value ? "primary" : "textPrimary"}>
                                {role.label}
                            </BlockTypography>
                        }
                        secondary={
                            <BlockTypography component="span" color={role.value === memberRole.value ? "primary" : "textSecondary"}>
                                {role.description}
                            </BlockTypography>
                        }
                        style={{ whiteSpace: "nowrap" }}
                    />
                </ListItem>
            ))}
        </StyledRoleSelect>
    );
}
const RoleSelect = memo(_RoleSelect, (prevProps, nextProps) => getObjectHash(prevProps, true) === getObjectHash(nextProps, true));

function _AddTeamMembers({
    members,
    onAddMember,
    onUpdateMember,
    onRemoveMember,
    onImportMembersFromFile = null,
    maxSeatCount = null,
    existingTeamMembers = {}, // This one is a remnant from old code
    isSignUpB = false
}) {
    const isMemberInvalid = member => {
        if (member.email.length === 0) {
            return false;
        }
        return member.email.length > 2 && !emailRegex.test(member.email) ||
            !!existingTeamMembers[member.email.toLowerCase()] ||
            members.filter(m => m.email.length > 0 && m.email === member.email.toLowerCase()).length > 1;
    };

    const isMemberListInvalid = members.some(member => isMemberInvalid(member));

    const uploadFileInputRef = useRef();

    const handleFileSelected = event => {
        const [file] = event.target?.files ?? event.dataTransfer?.files ?? [];
        if (file) {
            onImportMembersFromFile(file);
        }
    };

    let allSeatsUsed = false;
    if (maxSeatCount) {
        allSeatsUsed = members.filter(member => member.role !== TEAM_USER_ROLES.MEMBER).length >= maxSeatCount;
    }

    return (<Container>
        {members.map((member, index) => (
            <MemberContainer key={member.id} isSignUpB={isSignUpB}>
                <div>
                    {index === 0 && <RoleLabel isSignUpB={isSignUpB}>Invite your team members</RoleLabel>}
                    <EmailInput
                        disabled={member.isDisabled}
                        type="email"
                        value={member.email}
                        onChange={event => onUpdateMember(member.id, "email", event.target.value)}
                        error={isMemberInvalid(member)}
                        isSignUpB={isSignUpB}
                        {...(!isSignUpB && { variant: "outlined", placeholder: "Email" })}
                        {...(isSignUpB && { label: "Email" })}
                    />
                </div>
                <div>
                    {index === 0 && <RoleLabel isSignUpB={isSignUpB} >Assign a role</RoleLabel>}
                    <RoleSelect
                        propKey="role"
                        member={member}
                        onUpdateMember={onUpdateMember}
                        isSignUpB={isSignUpB}
                        isFirst={index === 0}
                    />
                </div>
                {index > 0 &&
                    <Icon
                        iconName="clear"
                        onClick={() => onRemoveMember(member.id)}
                        style={isSignUpB ? { marginTop: "20px" } : {}}
                    />
                }
            </MemberContainer>
        ))}
        <AddMembersControlsContainer>
            {!allSeatsUsed && <AddMemberControlContainer disabled={isMemberListInvalid} onClick={() => !isMemberListInvalid && onAddMember()}>
                <Icon iconName="add_circle" />
                <AddMemberSpan>Add Another</AddMemberSpan>
            </AddMemberControlContainer>}
            {onImportMembersFromFile && <AddMemberControlContainer onClick={() => uploadFileInputRef.current.click()}>
                <Icon iconName="upload" />
                <AddMemberSpan>Import from file</AddMemberSpan>
                <UploadFileInput
                    ref={uploadFileInputRef}
                    type="file"
                    accept={"text/csv, text/plain"}
                    onChange={handleFileSelected}
                />
            </AddMemberControlContainer>}
        </AddMembersControlsContainer>
    </Container>);
}

const AddTeamMembers = memo(_AddTeamMembers, (prevProps, nextProps) => getObjectHash(prevProps, true) === getObjectHash(nextProps, true));

export default AddTeamMembers;
